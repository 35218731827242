
// eslint-disable-next-line require-await
export default defineNuxtRouteMiddleware(async (to, from) => {
  if(!process.client){
    return
  }

  const rootStore = useRootStore();
  if (from.name ) {
    rootStore.previousRoute = from.name.toString();
  }
});