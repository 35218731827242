
export const IGNORE_ERROR_MESSAGES_STARTS_WITH = [
  'Post not found:',
  'Page not found:',
  'Not found:',
  'Hydration completed but contains mismatches',
]

export const IGNORE_ERROR_TYPES = [
  'TypeMismatchError',
  'isDestroyed',
  'ResizeObserver',
  'webkitExitFullScreen',
  'ReportingObserver',
  'HierarchyRequestError',
  'The operation is insecure',
  'in strict mode',
  'Blocked a frame with origin',
  'Ignored attempt to cancel',
  'instantSearchSDKJSBridgeClearHighlight',
  'change_ua',
  'ibFindAllVideos',
  'loadDownloads',
  'processRandomSelector',
  'Illegal invocation',
  'global code',
  'Decoding failed',
  'Failed to fetch',
  'PaymentAutofillConfig',
  'Navigation cancelled',
  'messagehandlers',
  'Non-Error promise rejection captured',
  'Load failed',
  'replaceState',
  'webkit.messageHandlers',
  'Aborted',
  'AbortError',
  'captured as promise rejection',
  'Failed to initialize WebGL'
]
