// Migrated
<template lang="pug">
slot(v-if="hasPermission")
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  props: {
    /**
     * List of keys to be truthy in the agentCookie object
     */
    requiredPermissions: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapState(useRootStore, ['agentCookie']),

    hasPermission () {
      return (
        this.agentCookie &&
        this.requiredPermissions.every(agentCookieKey => !!this.agentCookie[agentCookieKey])
      )
    }
  }
})
</script>
