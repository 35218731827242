<template lang="pug">
NuxtLoadingIndicator(:color="loadingColor")
.position-relative(:class="wrapperClass")
  NuxtPage

  slot

  LazyCognitoContainer(v-if="locale === 'sv'")
  CookieConsent
  NewsletterPopup(v-if="locale === 'no'")

  AgentComponent
    LazyAgentCall
    LazyTranslationTools
</template>

<script setup>
useConfigStore()
const route = useRoute()
const { t } = useI18n()

onServerPrefetch(async () => {
  const rootStore = useRootStore()
  const localeStore = useLocaleStore()

  await rootStore.handleAgentCookie(route.query?.a),

  await Promise.allSettled([
    ...(localeStore.isCharter ? [] : [rootStore.fetchTripTypesSidebar()]),
    rootStore.fetchAgentCall(),
    rootStore.fetchTopNavbar(),
    rootStore.fetchFooter(),
    rootStore.fetchAlert()
  ])
})

const rootStore = useRootStore()
const {
  modalActive,
} = storeToRefs(rootStore)
const { locale, isCharter } = useLocaleStore()

useHead({
  htmlAttrs: {
    lang: () => locale === 'zz' ? 'sv' : locale,
  },
})

useSchemaOrg([
  defineWebSite({
    description: t('homeDescription'),
    inLanguage: locale === 'zz' ? 'sv-SE' : locale === 'no' ? 'nb-NO' : 'sv-SE',
  })
])

const wrapperClass = computed(() => {
  const classes = []

  if (!modalActive.value) {
    classes.push('overflow-clip')
  }

  return classes.join(' ')
})

const loadingColor = computed(() => isCharter ? '#fecb21' : '#065BA2')
</script>
