// Translated
// Migrated
<template lang="pug">
div(
  :class="wrapperClass"
  data-loading="true"
)
  div(:class="parentClass")
    LazyLottie(
      v-if="isCharter",
      name="solresorLoading",
      :autoplay="autoplay",
      :loop="loop",
      :width="width"
    )
    .box-text.text-center(v-else)
      .spinner-grow.text-primary.mb-5
  slot
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  props: {
    autoplay: {
      type: Boolean,
      default: true
    },

    loop: {
      type: Boolean,
      default: true
    },

    width: {
      type: Number,
      default: 200
    },

    parentClass: {
      type: String,
      default: 'py-3 d-flex justify-content-center'
    },

    wrapperClass: {
      type: String,
      default: 'text-center'
    }
  },

  computed: {
    ...mapState(useLocaleStore, {
      isCharter: 'isCharter'
    }),
  }
})
</script>

<style lang="scss" scoped>
.spinner-xl {
  .spinner-grow {
    width: 3rem;
    height: 3rem;
  }
}
</style>
