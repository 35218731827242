import { default as _91slug_93DYUBxxXufJMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/tourleader/[id]/[slug].vue?macro=true";
import { default as _91slug_93PqMaqYLr0PMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/titinerary/[slug].vue?macro=true";
import { default as _91name_93RruXwuqLZzMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/shipinfo/[name].vue?macro=true";
import { default as _91id_93jrjWpns0yoMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/payment/[id].vue?macro=true";
import { default as _91id_93BhnWWsFUEbMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/omdome/[id].vue?macro=true";
import { default as _91tab_93Xdhvdw498NMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/mytrip/[tab].vue?macro=true";
import { default as _91tab_93X6PaY1BcAfMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/info/lectures/[tab].vue?macro=true";
import { default as _91tab_93a1NNMhXR7bMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/info/connections/[tab].vue?macro=true";
import { default as _91tab_93x5ZQ0PeGJ0Meta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/info/about/[tab].vue?macro=true";
import { default as _91id_93dUk3EZPEFaMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/fn/[id].vue?macro=true";
import { default as _91slug_93hii4qV8ygjMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/competition/[slug].vue?macro=true";
import { default as _91name_93DW5IcEmcKDMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/charterpackagescampaign/[name].vue?macro=true";
import { default as _91slug_933gkW9rskh2Meta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/category/[slug].vue?macro=true";
import { default as _91ocr_93AIHjo0QXVgMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/bookingfaq/[ocr].vue?macro=true";
import { default as _91ocr_93hCwi3HA7e7Meta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/booking-finish/[ocr].vue?macro=true";
import { default as _91id_93kF5grQHBvQMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/booking-confirmation/[id].vue?macro=true";
import { default as _91step_93LA3JipjVMKMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/book/[tripid]/[step].vue?macro=true";
import { default as _91slug_93C3v0uLHuUxMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/auth/[slug].vue?macro=true";
import { default as _91tab_93sHNQtyc1HUMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/[triptype]/[slug]/[tab].vue?macro=true";
import { default as _91tab_93pVhJ4cHCA3Meta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/___sv_destination/[slug]/[tab].vue?macro=true";
import { default as activitiesi8MN1BbBDhMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/activities.vue?macro=true";
import { default as callbackrbZYGgdI9tMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/auth/callback.vue?macro=true";
import { default as favourites1v5Z0t3jviMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/auth/favourites.vue?macro=true";
import { default as my_45bookingsS2eGcJpFv4Meta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/auth/my-bookings.vue?macro=true";
import { default as blackweekLj9R2DRMJGMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/blackweek.vue?macro=true";
import { default as _91slug_93UnwBGk4eaTMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/blog/[slug].vue?macro=true";
import { default as indexnYezLaX2eJMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/blog/index.vue?macro=true";
import { default as byebyeiezdwGe2xRqGMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/byebyeie.vue?macro=true";
import { default as index5NcKPDWps4Meta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/calendar/index.vue?macro=true";
import { default as lastminuteyDgv0dnOfGMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/calendar/lastminute.vue?macro=true";
import { default as _91name_935MAnbZbq0vMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/campaign/[name].vue?macro=true";
import { default as indexbMrXWn9oEoMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/campaign/index.vue?macro=true";
import { default as catalogueSlVZJ7WrcrMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/catalogue.vue?macro=true";
import { default as charterlastminuteRENDstxzWvMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/charterlastminute.vue?macro=true";
import { default as gruppresorW4QpeZeObeMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/charterpackagescampaign/gruppresor.vue?macro=true";
import { default as chartersearchWPdWU5lDt6Meta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/chartersearch.vue?macro=true";
import { default as complaintWXiKkte27qMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/complaint.vue?macro=true";
import { default as indexke1uWqi2xQMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/destination/index.vue?macro=true";
import { default as dinbokning_45aktuellew0QUw31lVMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/dinbokning-aktuell.vue?macro=true";
import { default as _91slug_93SMC9bfXUZLMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/event/[slug].vue?macro=true";
import { default as indexgkfa5HqFnYMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/event/index.vue?macro=true";
import { default as faqAlq620nsTEMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/faq.vue?macro=true";
import { default as indextrhn73Sj2HMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/favourites/index.vue?macro=true";
import { default as _91id_93rCEDg1Lt4wMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/giftcard/[id].vue?macro=true";
import { default as indexTsqKrvJatGMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/giftcard/index.vue?macro=true";
import { default as _91id_93bJpoBtvyMAMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/hotel/[id].vue?macro=true";
import { default as indexxYqcWGHaVBMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/hotel/index.vue?macro=true";
import { default as _91name_93osFcmW6VYrMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/hotels/[name].vue?macro=true";
import { default as indexR1BXnIhPyjMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/hotels/index.vue?macro=true";
import { default as indexvQXlpCywKhMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/index.vue?macro=true";
import { default as _91slug_931i0fjrnXs3Meta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/info/[slug].vue?macro=true";
import { default as grouptravel0uDe1vWnfaMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/info/grouptravel.vue?macro=true";
import { default as indexEV8kDiAwUqMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/info/index.vue?macro=true";
import { default as lastminute0SOvdUNrODMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/lastminute.vue?macro=true";
import { default as mapOdsLcqCNQ3Meta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/map.vue?macro=true";
import { default as mobileAppo3UPZhuO0MMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/mobileApp.vue?macro=true";
import { default as dinbokning2w06JD7y4GMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/mytrip/dinbokning.vue?macro=true";
import { default as newsletter8vQfXiUGOQMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/newsletter.vue?macro=true";
import { default as offlinewAWLXOeeB9Meta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/offline.vue?macro=true";
import { default as packagessearchxRRHxXp693Meta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/packagessearch.vue?macro=true";
import { default as failedNpYqAgLjhaMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/payment/failed.vue?macro=true";
import { default as indexHapKdKyICNMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/review/index.vue?macro=true";
import { default as searchfj5XuvURgwMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/search.vue?macro=true";
import { default as indexLORmOioHrHMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/tourleader/index.vue?macro=true";
import { default as verification_45doneowZoNYzangMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/verification-done.vue?macro=true";
import { default as component_45stubg9wqs5uNRlMeta } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubg9wqs5uNRl } from "/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "tourleader-id",
    path: "/reseledare/:id()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/tourleader/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "titinerary",
    path: "/dagprogram",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/titinerary/[slug].vue").then(m => m.default || m)
  },
  {
    name: "shipinfo",
    path: "/fartyg",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/shipinfo/[name].vue").then(m => m.default || m)
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: "omdome",
    path: "/omdome",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/omdome/[id].vue").then(m => m.default || m)
  },
  {
    name: "mytrip",
    path: "/dinresa",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/mytrip/[tab].vue").then(m => m.default || m)
  },
  {
    name: "info-lectures",
    path: "/info/forelasningar",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/info/lectures/[tab].vue").then(m => m.default || m)
  },
  {
    name: "info-connections",
    path: "/info/anslutning",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/info/connections/[tab].vue").then(m => m.default || m)
  },
  {
    name: "info-about",
    path: "/info/om",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/info/about/[tab].vue").then(m => m.default || m)
  },
  {
    name: "fn",
    path: "/fn",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/fn/[id].vue").then(m => m.default || m)
  },
  {
    name: "competition",
    path: "/tavling",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/competition/[slug].vue").then(m => m.default || m)
  },
  {
    name: "charterpackagescampaign",
    path: "/charterpackagescampaign",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/charterpackagescampaign/[name].vue").then(m => m.default || m)
  },
  {
    name: "category",
    path: "/kategori",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "bookingfaq",
    path: "/boknings-faq",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/bookingfaq/[ocr].vue").then(m => m.default || m)
  },
  {
    name: "booking-finish",
    path: "/booking-finish",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/booking-finish/[ocr].vue").then(m => m.default || m)
  },
  {
    name: "booking-confirmation",
    path: "/booking-confirmation",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/booking-confirmation/[id].vue").then(m => m.default || m)
  },
  {
    name: "book-tripid",
    path: "/boka/:tripid()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/book/[tripid]/[step].vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/medlem",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/auth/[slug].vue").then(m => m.default || m)
  },
  {
    name: "triptype-slug",
    path: "/:triptype()/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/[triptype]/[slug]/[tab].vue").then(m => m.default || m)
  },
  {
    name: "sv-destination-slug",
    path: "/destination/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/___sv_destination/[slug]/[tab].vue").then(m => m.default || m)
  },
  {
    name: "sv-destination-slug-tab",
    path: "/destination/:slug()/:tab()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/___sv_destination/[slug]/[tab].vue").then(m => m.default || m)
  },
  {
    name: "triptype-slug-tab",
    path: "/:triptype()/:slug()/:tab()",
    meta: _91tab_93sHNQtyc1HUMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/[triptype]/[slug]/[tab].vue").then(m => m.default || m)
  },
  {
    name: "activities",
    path: "/activities",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/activities.vue").then(m => m.default || m)
  },
  {
    name: "auth-slug",
    path: "/medlem/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/auth/[slug].vue").then(m => m.default || m)
  },
  {
    name: "auth-callback",
    path: "/medlem/callback",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/auth/callback.vue").then(m => m.default || m)
  },
  {
    name: "auth-favourites",
    path: "/medlem/favoriter",
    meta: favourites1v5Z0t3jviMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/auth/favourites.vue").then(m => m.default || m)
  },
  {
    name: "auth-my-bookings",
    path: "/medlem/mina-bokningar",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/auth/my-bookings.vue").then(m => m.default || m)
  },
  {
    name: "blackweek",
    path: "/blackweek",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/blackweek.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blogg/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blogg",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "book-tripid-step",
    path: "/boka/:tripid()/:step()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/book/[tripid]/[step].vue").then(m => m.default || m)
  },
  {
    name: "booking-confirmation-id",
    path: "/booking-confirmation/:id()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/booking-confirmation/[id].vue").then(m => m.default || m)
  },
  {
    name: "booking-finish-ocr",
    path: "/booking-finish/:ocr()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/booking-finish/[ocr].vue").then(m => m.default || m)
  },
  {
    name: "bookingfaq-ocr",
    path: "/boknings-faq/:ocr()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/bookingfaq/[ocr].vue").then(m => m.default || m)
  },
  {
    name: "byebyeie",
    path: "/byebyeie",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/byebyeie.vue").then(m => m.default || m)
  },
  {
    name: "calendar",
    path: "/kalender",
    meta: index5NcKPDWps4Meta || {},
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: "calendar-lastminute",
    path: "/kalender/sista-minuten",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/calendar/lastminute.vue").then(m => m.default || m)
  },
  {
    name: "campaign-name",
    path: "/kampanj/:name()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/campaign/[name].vue").then(m => m.default || m)
  },
  {
    name: "campaign",
    path: "/kampanj",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/campaign/index.vue").then(m => m.default || m)
  },
  {
    name: "catalogue",
    path: "/katalog",
    meta: catalogueSlVZJ7WrcrMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/catalogue.vue").then(m => m.default || m)
  },
  {
    name: "category-slug",
    path: "/kategori/:slug()",
    meta: _91slug_933gkW9rskh2Meta || {},
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "charterlastminute",
    path: "/charterlastminute",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/charterlastminute.vue").then(m => m.default || m)
  },
  {
    name: "charterpackagescampaign-name",
    path: "/charterpackagescampaign/:name()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/charterpackagescampaign/[name].vue").then(m => m.default || m)
  },
  {
    name: "charterpackagescampaign-gruppresor",
    path: "/charterpackagescampaign/gruppresor",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/charterpackagescampaign/gruppresor.vue").then(m => m.default || m)
  },
  {
    name: "chartersearch",
    path: "/chartersearch",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/chartersearch.vue").then(m => m.default || m)
  },
  {
    name: "competition-slug",
    path: "/tavling/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/competition/[slug].vue").then(m => m.default || m)
  },
  {
    name: "complaint",
    path: "/reklamation",
    meta: complaintWXiKkte27qMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/complaint.vue").then(m => m.default || m)
  },
  {
    name: "destination",
    path: "/destination",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/destination/index.vue").then(m => m.default || m)
  },
  {
    name: "dinbokning-aktuell",
    path: "/dinbokning-aktuell",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/dinbokning-aktuell.vue").then(m => m.default || m)
  },
  {
    name: "event-slug",
    path: "/event/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/event/[slug].vue").then(m => m.default || m)
  },
  {
    name: "event",
    path: "/event",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/event/index.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "favourites",
    path: "/favourites",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/favourites/index.vue").then(m => m.default || m)
  },
  {
    name: "fn-id",
    path: "/fn/:id()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/fn/[id].vue").then(m => m.default || m)
  },
  {
    name: "giftcard-id",
    path: "/presentkort/:id()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/giftcard/[id].vue").then(m => m.default || m)
  },
  {
    name: "giftcard",
    path: "/presentkort",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/giftcard/index.vue").then(m => m.default || m)
  },
  {
    name: "hotel-id",
    path: "/hotel/:id()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/hotel/[id].vue").then(m => m.default || m)
  },
  {
    name: "hotel",
    path: "/hotel",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: "hotels-name",
    path: "/hotell/:name()",
    meta: _91name_93osFcmW6VYrMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/hotels/[name].vue").then(m => m.default || m)
  },
  {
    name: "hotels",
    path: "/hotell",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/hotels/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info-slug",
    path: "/info/:slug()",
    meta: _91slug_931i0fjrnXs3Meta || {},
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/info/[slug].vue").then(m => m.default || m)
  },
  {
    name: "info-about-tab",
    path: "/info/om/:tab()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/info/about/[tab].vue").then(m => m.default || m)
  },
  {
    name: "info-connections-tab",
    path: "/info/anslutning/:tab()",
    meta: _91tab_93a1NNMhXR7bMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/info/connections/[tab].vue").then(m => m.default || m)
  },
  {
    name: "info-grouptravel",
    path: "/info/gruppresor",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/info/grouptravel.vue").then(m => m.default || m)
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info-lectures-tab",
    path: "/info/forelasningar/:tab()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/info/lectures/[tab].vue").then(m => m.default || m)
  },
  {
    name: "lastminute",
    path: "/sista-minuten-erbjudanden",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/lastminute.vue").then(m => m.default || m)
  },
  {
    name: "map",
    path: "/karta",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/map.vue").then(m => m.default || m)
  },
  {
    name: "mobileApp",
    path: "/app",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/mobileApp.vue").then(m => m.default || m)
  },
  {
    name: "mytrip-tab",
    path: "/dinresa/:tab()",
    meta: _91tab_93Xdhvdw498NMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/mytrip/[tab].vue").then(m => m.default || m)
  },
  {
    name: "mytrip-dinbokning",
    path: "/mytrip/dinbokning",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/mytrip/dinbokning.vue").then(m => m.default || m)
  },
  {
    name: "newsletter",
    path: "/nyhetsbrev",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "offline",
    path: "/offline",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/offline.vue").then(m => m.default || m)
  },
  {
    name: "omdome-id",
    path: "/omdome/:id()",
    meta: _91id_93BhnWWsFUEbMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/omdome/[id].vue").then(m => m.default || m)
  },
  {
    name: "packagessearch",
    path: "/packagessearch",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/packagessearch.vue").then(m => m.default || m)
  },
  {
    name: "payment-id",
    path: "/payment/:id()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: "payment-failed",
    path: "/payment/failed",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/payment/failed.vue").then(m => m.default || m)
  },
  {
    name: "review",
    path: "/utvardera-resa",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/review/index.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/sok",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "shipinfo-name",
    path: "/fartyg/:name()",
    meta: _91name_93RruXwuqLZzMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/shipinfo/[name].vue").then(m => m.default || m)
  },
  {
    name: "titinerary-slug",
    path: "/dagprogram/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/titinerary/[slug].vue").then(m => m.default || m)
  },
  {
    name: "tourleader-id-slug",
    path: "/reseledare/:id()/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/tourleader/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "tourleader",
    path: "/reseledare",
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/tourleader/index.vue").then(m => m.default || m)
  },
  {
    name: "verification-done",
    path: "/verification-done",
    meta: verification_45doneowZoNYzangMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/pages/verification-done.vue").then(m => m.default || m)
  },
  {
    name: component_45stubg9wqs5uNRlMeta?.name,
    path: "/dinbokning",
    component: component_45stubg9wqs5uNRl
  }
]