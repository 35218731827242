export default {
  "banner-default": () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/layouts/banner-default.vue").then(m => m.default || m),
  "banner-trip": () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/layouts/banner-trip.vue").then(m => m.default || m),
  "charter-default": () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/layouts/charter-default.vue").then(m => m.default || m),
  "charter-destination": () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/layouts/charter-destination.vue").then(m => m.default || m),
  "charter-empty": () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/layouts/charter-empty.vue").then(m => m.default || m),
  "charter-page": () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/layouts/charter-page.vue").then(m => m.default || m),
  default: () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/layouts/default.vue").then(m => m.default || m),
  "map-layout": () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/layouts/map-layout.vue").then(m => m.default || m),
  "noheader-layout": () => import("/home/ubuntu/deployments/2024-09-13-0832/rtg-monorepo/layouts/noheader-layout.vue").then(m => m.default || m)
}